<template>
        <defaultSec :title="'订单查询'">
            <orderListInfo :formParam="formParam"></orderListInfo>
        </defaultSec>

</template>

<script>
    import defaultSec from '@c/defaultSection'
    import orderListInfo from "@c/orderSystem/orderListInfo";
    export default {
        data() {
            return {
                formParam:{
                    pageSize:15,
                    currentPage:1,
                    externalOrderNo:this.$route.query.externalOrderNo || '',
                    externalMainOrderNo: this.$route.query.orderMainNo ||'',
                    buyerId:this.$route.query.buyerId ||''
                },
            }
        },
        components:{
            defaultSec,orderListInfo
        },
    }
</script>


<style scoped>

</style>